var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-simple-table', {
          staticClass: "mt-5",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('tbody', _vm._l(_vm.dependants, function (dependant, idx) {
                return _c('tr', {
                  key: idx
                }, [_c('td', [_c('v-text-field', {
                  staticClass: "pa-5",
                  attrs: {
                    "dense": "",
                    "type": "text",
                    "placeholder": "Name",
                    "label": "Name",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  model: {
                    value: dependant.name,
                    callback: function callback($$v) {
                      _vm.$set(dependant, "name", $$v);
                    },
                    expression: "dependant.name"
                  }
                })], 1), _c('td', [_c('v-select', {
                  staticClass: "pa-5",
                  attrs: {
                    "dense": "",
                    "items": _vm.yobOptions,
                    "label": "Year of birth",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  model: {
                    value: dependant.yob,
                    callback: function callback($$v) {
                      _vm.$set(dependant, "yob", $$v);
                    },
                    expression: "dependant.yob"
                  }
                })], 1), _c('td', [_c('v-icon', {
                  attrs: {
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  on: {
                    "click": function click($event) {
                      $event.preventDefault();
                      return _vm.removeDependant(idx);
                    }
                  }
                }, [_vm._v(" mdi-delete ")])], 1)]);
              }), 0)];
            },
            proxy: true
          }])
        }), _c('v-btn', {
          attrs: {
            "color": "primary",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "click": _vm.addDependant
          }
        }, [_vm._v(" Add new ")])], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }