<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-simple-table class="mt-5">
            <template #default>
              <tbody>
              <tr
                v-for="(dependant, idx) in dependants"
                :key="idx"
              >
                <td>
                  <v-text-field
                    dense
                    type="text"
                    v-model="dependant.name"
                    placeholder="Name"
                    label="Name"
                    class="pa-5"
                    :disabled="FIELDS_DISABLED"
                  />
                </td>

                <td>
                  <v-select
                    dense
                    v-model="dependant.yob"
                    :items="yobOptions"
                    label="Year of birth"
                    class="pa-5"
                    :disabled="FIELDS_DISABLED"
                  />
                </td>

                <td>
                  <v-icon @click.prevent="removeDependant(idx)" :disabled="FIELDS_DISABLED">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn color="primary" @click="addDependant" :disabled="FIELDS_DISABLED">
            Add new
          </v-btn>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'DependantsSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialDependants: [],
      dependants: [],
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialDependants = clone(this.existedData);
    this.dependants = clone(this.existedData);

    this.$emit('componentReady');
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    yobOptions() {
      const yobArray = [];

      const now = new Date();
      const lastDate = now.getFullYear();

      const firstDate = lastDate - 21;

      for (let index = lastDate; index >= firstDate; index -= 1) {
        yobArray.unshift(index);
      }

      yobArray.unshift('Elder');

      return yobArray;
    },
  },

  methods: {
    addDependant() {
      const dependantData = {
        name: '',
        yob: '',
      };

      this.dependants.push(dependantData);
    },

    removeDependant(idxDependants) {
      this.dependants.splice(idxDependants, 1);
    },
  },
};
</script>
